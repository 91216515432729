package es.cinfo.tiivii.core.modules.product

import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.modules.product.model.GetProductCheckoutApiRequest
import es.cinfo.tiivii.core.modules.product.model.GetUserPortalSessionApiRequest
import es.cinfo.tiivii.core.modules.product.model.ProductModel.ApiResponse.*
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.di.diContainer
import io.ktor.client.request.*
import org.kodein.di.instance

/**
 * Network datasource for product operations
 */
internal interface ProductApi {

    suspend fun getProducts(): Outcome<Products, NetworkError>

    suspend fun getUserProducts(username: String): Outcome<ProductsLoad, NetworkError>

    suspend fun getProductCheckout(id: Int, username: String, successUrl: String, cancelUrl: String, language: String): Outcome<ProductCheckout, NetworkError>

    suspend fun getProductsDashboard(username: String, returnUrl: String?, language: String): Outcome<ProductsDashboard, NetworkError>
}

/**
 * Default implementation of [ProductApi] using Ktor
 */
internal class DefaultProductApi : ProductApi {
    private val http: HttpModule by diContainer.instance()

    private val baseEndpoint: String by lazy {
        val configModule: ConfigModule by diContainer.instance()
        "${configModule.getEnvConfig().backendUrl}/sdp/${configModule.getEnvConfig().apiName}"
    }

    override suspend fun getProducts(): Outcome<Products, NetworkError> {
        val endpoint = "$baseEndpoint/products"
        return http.getAsOutcome(endpoint = endpoint)
    }

    override suspend fun getUserProducts(username: String): Outcome<ProductsLoad, NetworkError> {
        val endpoint = "$baseEndpoint/products/$username"
        return http.getAsOutcome(endpoint = endpoint)
    }

    override suspend fun getProductCheckout(id: Int, username: String, successUrl: String, cancelUrl: String, language: String): Outcome<ProductCheckout, NetworkError> {
        val endpoint = "$baseEndpoint/checkout/$username/$id?locale=$language"
        return http.postAsOutcome(endpoint = endpoint) {
            header("Content-Type", "application/json")
            body = GetProductCheckoutApiRequest(successUrl, cancelUrl)
        }
    }

    override suspend fun getProductsDashboard(
        username: String,
        returnUrl: String?,
        language: String
    ): Outcome<ProductsDashboard, NetworkError> {
        val endpoint = "$baseEndpoint/portal/$username?locale=$language"
        return http.postAsOutcome(endpoint = endpoint) {
            header("Content-Type", "application/json")
            body = GetUserPortalSessionApiRequest(returnUrl)
        }
    }

}