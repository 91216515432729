package es.cinfo.tiivii.user.profile.store

import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.interest.model.Model.Interest
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.Model.Avatar
import es.cinfo.tiivii.core.modules.game.model.GameModel.Model.Achievement
import es.cinfo.tiivii.core.modules.game.model.GameModel.Model.Reward
import es.cinfo.tiivii.core.modules.product.model.ProductModel
import es.cinfo.tiivii.core.modules.product.model.ProductModel.Model.Product
import es.cinfo.tiivii.core.modules.product.model.ProductModel.Model.ProductCheckout
import es.cinfo.tiivii.core.userstats.UserStatsModel.Model.Ranking
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState
import es.cinfo.tiivii.user.profile.store.UserStore.*
import es.cinfo.tiivii.user.role.Role

internal interface UserStore :
    Store<Intent, State, Label> {

    sealed class Action {
        object GetAvailableLanguages : Action()
        object LoadUserData : Action()
        object LoadUserProducts : Action()
    }

    sealed class Intent {
        object ReloadUserData : Intent()
        object ReloadUserStats: Intent()
        object Logout : Intent()
        object DeleteAccount : Intent()
        object GetAvailableInterests : Intent()
        object GetAvailableAvatars : Intent()
        data class UpdateInterests(val interests: Set<Interest>) : Intent()
        data class UpdateLanguage(val language: String) : Intent()
        data class UpdateAvatar(val avatar: Avatar) : Intent()
        data class UpdateCredentials(val oldCredentials: String, val newCredentials: String) : Intent()
        data class UpdateFirstName(val firstName: String) : Intent()
        data class UpdateLastName(val lastName: String) : Intent()
        object LogUserProfileView : Intent()
        data class PublishContent(val contentId: Int, val payload: String) : Intent()
        data class GetProductCheckout(val id: Int, val successUrl: String?, val cancelUrl: String?) : Intent()
        object ReloadProducts: Intent()
        object LoadMoreUserProducts: Intent()
        data class CheckCredentialsSecurity(val credentials: String): Intent()
        data class GetProductsDashboard(val returnUrl: String?): Intent()
    }

    data class State(
        val loadingUser: LoadState,
        val availableRoles: Set<Role>,
        val username: String?,
        val email: String?,
        val birthday: String?,
        val availableLanguages: Set<String>,
        val language: String?,
        val availableAvatars: Set<Avatar>,
        val avatar: Avatar?,
        val availableInterests: Set<Interest>,
        val interests: Set<Interest>,
        val credentials: String?,
        val firstName: String?,
        val lastName: String?,
        val acceptedLegalConditionsVersion: Int?,
        val acceptedLegalConditions: String?,

        val loadingUserStats: LoadState,
        val ranking: Ranking?,
        val achievements: List<Achievement>,
        val rewards: List<Reward>,

        val loadingProducts: LoadState,
        val products: List<Product>,
        val userProducts: List<Product>,
        val hasMoreUserProducts: Boolean,
        val loadingProductCheckout: LoadState,
        val latestProductCheckout: ProductCheckout?,

        val loadingProductsDashboard: LoadState,
        val latestProductsDashboard: ProductModel.Model.ProductsDashboard?
    )

    sealed class Label {
        data class UnexpectedError(val code: String) : Label()
        object UserSessionExpired : Label()
        object RequestTimedOut : Label()

        object LoggedOut : Label()
        object DeleteAccountFailed : Label()
        object NoUserLoggedIn : Label()
        object UserDataLoadFailed : Label()
        object AvatarsRetrievalFailed : Label()
        object InterestsRetrievalFailed : Label()
        object FirstNameUpdateFailed : Label()
        object LastNameUpdateFailed : Label()
        object AvatarUpdateFailed : Label()
        object InterestsUpdateFailed : Label()
        object LanguageUpdateFailed : Label()
        object InvalidCredentials : Label()
        object InsecureCredentials : Label()
        object SecureCredentials : Label()
        object CredentialsUpdateFailed : Label()
        object AccountDeleted : Label()
        data class ContentPublication(val granted: Boolean) : Label()

        data class IllegalOperationError(val reason: String) : Label()
    }

}
