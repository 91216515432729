package es.cinfo.tiivii.core.modules.share

import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.share.model.ShareModel
import es.cinfo.tiivii.core.modules.share.model.ShortLinkApiRequest
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.network.HttpModule
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.di.diContainer
import io.ktor.client.request.*
import org.kodein.di.instance

internal interface ShareApi {
    suspend fun getDetailLink(
        contentId: Int,
        contentTitle: String,
        contentDescription: String,
        contentImageLink: String?
    ): Outcome<String, NetworkError>

    suspend fun getPlayerLink(
        contentId: Int,
        contentType: String,
        contentTitle: String,
        contentDescription: String,
        contentImageLink: String?
    ): Outcome<String, NetworkError>

    suspend fun getAppLink(): Outcome<String, NetworkError>
}

internal class FirebaseShareApi : ShareApi {

    private val http: HttpModule by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    private val shortLinksEndpoint: String by lazy {
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${configModule.getEnvConfig().firebaseApiKey}"
    }

    override suspend fun getDetailLink(
        contentId: Int,
        contentTitle: String,
        contentDescription: String,
        contentImageLink: String?
    ): Outcome<String, NetworkError> {
        println("richie: ShareApi.getDetailLink: contentId: $contentId, contentTitle: $contentTitle, contentDescription: $contentDescription, contentImageLink: $contentImageLink")
        val requestBody = ShortLinkApiRequest(
            with(configModule.getEnvConfig()) {
                ShortLinkApiRequest.DynamicLink(
                    domainUriPrefix = firebaseDynamicLinkUrl,
                    link = "${frontendUrl}/videodetail/${contentId}",
                    androidInfo = ShortLinkApiRequest.DynamicLink.AndroidInfo(
                        androidPackageName = androidAppId,
                        androidFallbackLink = "${frontendUrl}/videodetail/${contentId}",
                    ),
                    iosInfo = ShortLinkApiRequest.DynamicLink.IosInfo(
                        iosBundleId = iosBundleId,
                        iosIpadBundleId = iosBundleId,
                        iosAppStoreId = iosAppId,
                        iosFallbackLink = "${frontendUrl}/videodetail/${contentId}"
                    ),
                    socialInfo = ShortLinkApiRequest.DynamicLink.SocialInfo(
                        title = contentTitle,
                        description = contentDescription,
                        imageLink = contentImageLink
                    )
                )
            }
        )
        println("richie: ShareApi.getDetailLink (body): $requestBody")
        return http.postAsOutcome<ShareModel.ApiResponse.ShortLink>(endpoint = shortLinksEndpoint) {
            header("Content-Type", "application/json")
            body = requestBody
        }.map {
            it.link
        }
    }

    override suspend fun getPlayerLink(
        contentId: Int,
        contentType: String,
        contentTitle: String,
        contentDescription: String,
        contentImageLink: String?
    ): Outcome<String, NetworkError> {
        return http.postAsOutcome<ShareModel.ApiResponse.ShortLink>(endpoint = shortLinksEndpoint) {
            header("Content-Type", "application/json")
            body = ShortLinkApiRequest(
                with(configModule.getEnvConfig()) {
                    ShortLinkApiRequest.DynamicLink(
                        domainUriPrefix = firebaseDynamicLinkUrl,
                        link = "${frontendUrl}/player/${contentId}/${contentType}",
                        androidInfo = ShortLinkApiRequest.DynamicLink.AndroidInfo(
                            androidPackageName = androidAppId,
                            androidFallbackLink = "${frontendUrl}/player/${contentId}/${contentType}}",
                        ),
                        iosInfo = ShortLinkApiRequest.DynamicLink.IosInfo(
                            iosBundleId = iosBundleId,
                            iosIpadBundleId = iosBundleId,
                            iosAppStoreId = iosAppId,
                            iosFallbackLink = "${frontendUrl}/player/${contentId}/${contentType}"
                        ),
                        socialInfo = ShortLinkApiRequest.DynamicLink.SocialInfo(
                            title = contentTitle,
                            description = contentDescription,
                            imageLink = contentImageLink
                        )
                    )
                }
            )
        }.map {
            it.link
        }
    }

    override suspend fun getAppLink(): Outcome<String, NetworkError> {
        return http.postAsOutcome<ShareModel.ApiResponse.ShortLink>(endpoint = shortLinksEndpoint) {
            header("Content-Type", "application/json")
            body = ShortLinkApiRequest(
                with(configModule.getEnvConfig()) {
                    ShortLinkApiRequest.DynamicLink(
                        domainUriPrefix = firebaseDynamicLinkUrl,
                        link = frontendUrl,
                        androidInfo = ShortLinkApiRequest.DynamicLink.AndroidInfo(
                            androidPackageName = androidAppId
                        ),
                        iosInfo = ShortLinkApiRequest.DynamicLink.IosInfo(
                            iosBundleId = iosBundleId,
                            iosIpadBundleId = iosBundleId,
                            iosAppStoreId = iosAppId,
                        )
                    )
                }
            )
        }.map {
            it.link
        }
    }

}