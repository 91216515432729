package es.cinfo.tiivii.user.profile.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.content.ContentService
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.interest.model.Model.Interest
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action.ChangeLanguage.NEW_LANGUAGE_KEY
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action.UpdateAvatar.AVATAR_KEY
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action.UpdateInterests.INTERESTS_KEY
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.Model.Avatar
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.product.ProductService
import es.cinfo.tiivii.core.modules.product.model.ProductModel
import es.cinfo.tiivii.core.modules.product.model.ProductModel.Model.ProductCheckout
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.user.model.Model
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import es.cinfo.tiivii.user.signup.usecase.AreCredentialsValid
import org.kodein.di.instance

internal class UpdateInterests(
    private val username: String,
    private val interests: Set<Interest>
) : OutcomeUseCase<Unit, UpdateInterests.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.PROFILE, UseCaseId.UPDATE_INTERESTS, errorId, networkError) {
        data class UnavailableUserUpdate(val error: NetworkError) : Error(
            asErrorId<UnavailableUserUpdate>(1),
            error
        )
    }

    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            userService.updateUser(
                username = username,
                interests = interests
            )
                .on {
                    interests.let {
                        // Interests update event log
                        LogEvent(
                            action = AnalyticsModel.Action.UpdateInterests,
                            keyValue = INTERESTS_KEY to interests.joinToString(",")
                        ).invoke()
                    }
                }
                .mapError {
                    Error.UnavailableUserUpdate(it)
                }
        }
}

internal class UpdateLanguage(
    private val username: String,
    private val language: String
) : OutcomeUseCase<Unit, UpdateLanguage.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.PROFILE, UseCaseId.UPDATE_LANGUAGE, errorId, networkError) {
        data class UnavailableUserUpdate(val error: NetworkError) : Error(
            asErrorId<UnavailableUserUpdate>(1),
            error
        )
    }

    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            userService.updateUser(
                username = username,
                language = language
            )
                .on {
                    language.let {
                        // User language change event log
                        LogEvent(AnalyticsModel.Action.ChangeLanguage, keyValue = NEW_LANGUAGE_KEY to language).invoke()
                    }
                }
                .mapError {
                    Error.UnavailableUserUpdate(it)
                }
        }
}

internal class UpdateAvatar(
    private val username: String,
    private val avatar: Avatar
) : OutcomeUseCase<Unit, UpdateAvatar.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.PROFILE, UseCaseId.UPDATE_AVATAR, errorId, networkError) {
        data class UnavailableUserUpdate(val error: NetworkError) : Error(
            asErrorId<UnavailableUserUpdate>(1),
            error
        )
    }

    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            userService.updateUser(
                username = username,
                avatar = avatar
            )
                .on {
                    avatar.let {
                        // Avatar update event log
                        LogEvent(
                            action = AnalyticsModel.Action.UpdateAvatar,
                            keyValue = AVATAR_KEY to avatar.id.toString()
                        ).invoke()
                    }
                }
                .mapError {
                    Error.UnavailableUserUpdate(it)
                }
        }
}

internal class UpdateCredentials(
    private val username: String,
    private val oldCredentials: String,
    private val newCredentials: String
) : OutcomeUseCase<Unit, UpdateCredentials.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.PROFILE, UseCaseId.UPDATE_CREDENTIALS, errorId, networkError) {
        data class UnavailableCredentialsUpdate(val error: NetworkError) : Error(
            asErrorId<UnavailableCredentialsUpdate>(1),
            error
        )
        object InsecureCredentials : Error(
            asErrorId<InsecureCredentials>(2)
        )
        object InvalidCredentials : Error(
            asErrorId<InvalidCredentials>(3)
        )
    }

    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            val areCredentialsValid = AreCredentialsValid(newCredentials).invoke()
            if (areCredentialsValid) {
                userService.updateUserCredentials(
                    username = username,
                    oldCredentials = oldCredentials,
                    credentials = newCredentials
                )
                    .on {
                        // Password update event log
                        LogEvent(action = AnalyticsModel.Action.UpdatePassword).invoke()
                    }
                    .mapError {
                        if (it is NetworkError.Http && it.json != null) {
                            val krakenError = it.json.asKrakenError()
                            if (krakenError.key == "incorrect-password") {
                                Error.InvalidCredentials
                            } else {
                                Error.UnavailableCredentialsUpdate(it)
                            }
                        } else {
                            Error.UnavailableCredentialsUpdate(it)
                        }
                    }
            } else {
                failure(Error.InsecureCredentials)
            }
        }
}

internal class UpdateFirstName(
    private val username: String,
    private val firstName: String
) : OutcomeUseCase<Unit, UpdateFirstName.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.PROFILE, UseCaseId.UPDATE_FIRST_NAME, errorId, networkError) {
        data class UnavailableUpdateUser(val error: NetworkError) : Error(
            asErrorId<UnavailableUpdateUser>(1),
            error
        )
    }

    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            userService.updateUser(
                username = username,
                firstName = firstName
            )
                .mapError {
                    Error.UnavailableUpdateUser(it)
                }
        }
}

internal class UpdateLastName(
    private val username: String,
    private val lastName: String
) : OutcomeUseCase<Unit, UpdateLastName.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.PROFILE, UseCaseId.UPDATE_LAST_NAME, errorId, networkError) {
        data class UnavailableUserUpdate(val error: NetworkError) : Error(
            asErrorId<UnavailableUserUpdate>(1),
            error
        )
    }

    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            userService.updateUser(
                username = username,
                lastName = lastName
            )
                .mapError {
                    Error.UnavailableUserUpdate(it)
                }
        }
}

internal class DeleteAccount: OutcomeUseCase<Unit, DeleteAccount.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.PROFILE, UseCaseId.DELETE_USER_ACCOUNT, errorId, networkError) {
        data class UnavailableUserDeletion(val error: NetworkError) : Error(
            asErrorId<UnavailableUserDeletion>(1),
            error
        )
    }

    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            userService.getCachedUser()!!.username
            userService.deleteAccount(userService.getCachedUser()!!.username)
                .mapError {
                    Error.UnavailableUserDeletion(it)
                }
        }
}

internal class PublishContent(val payload: String): OutcomeUseCase<Boolean, PublishContent.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.PROFILE, UseCaseId.PUBLISH_CONTENT, errorId, networkError) {
        data class UnavailableContentPublication(val error: NetworkError) : Error(
            asErrorId<UnavailableContentPublication>(1),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Boolean, Error>
        get() = {
            contentService.publishContent(payload)
                .mapError { Error.UnavailableContentPublication(it) }
        }
}

internal class LoadProducts: OutcomeUseCase<Model.ProductsLoad, LoadProducts.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.PROFILE, UseCaseId.LOAD_PRODUCTS, errorId, networkError) {
        data class UnavailableProducts(val error: NetworkError) : Error(
            asErrorId<UnavailableProducts>(1),
            error
        )
        data class UnavailableUserProducts(val error: NetworkError) : Error(
            asErrorId<UnavailableUserProducts>(2),
            error
        )
        object NoUserSession : Error(
            asErrorId<NoUserSession>(3)
        )
        object ProductModuleDisabled : Error(
            asErrorId<ProductModuleDisabled>(4)
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val productService: ProductService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Model.ProductsLoad, Error>
        get() = {
            val user = authService.getStoredAuth()?.username
            if (user == null) {
                failure(Error.NoUserSession)
            } else {
//                    val products = productService.getProducts()
//                        .mapError { Error.UnavailableProducts(it) }.getOrAbort()
                val userProducts = productService.getUserProducts(user)
                    .mapError { Error.UnavailableUserProducts(it) }.getOrAbort()
                success(Model.ProductsLoad(emptyList(), userProducts))
            }
        }
}

internal class GetProductCheckout(val id: Int,
                                  private val successUrl: String,
                                  private val cancelUrl: String): OutcomeUseCase<ProductCheckout, GetProductCheckout.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.PROFILE, UseCaseId.GET_PRODUCT_CHECKOUT, errorId, networkError) {
        data class UnavailableProductCheckout(val error: NetworkError) : Error(
            asErrorId<UnavailableProductCheckout>(1),
            error
        )
        object NoUserSession : Error(
            asErrorId<NoUserSession>(2)
        )
        object ProductModuleDisabled : Error(
            asErrorId<ProductModuleDisabled>(3)
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val productService: ProductService by diContainer.instance()
    private val configService: ConfigModule by diContainer.instance()
    private val userService: UserService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ProductCheckout, Error>
        get() = {
            val username = authService.getStoredAuth()?.username
            if (username == null) {
                failure(Error.NoUserSession)
            } else {
                if (!configService.getCoreConfig().content.productBuyoutEnabled) {
                    failure(Error.ProductModuleDisabled)
                } else {
                    val user = userService.getUser(username).getOrNull()
                    val language = user?.preferredLanguage ?: configService.getCoreConfig().signup.defaultLanguage
                    val productCheckout = productService.getProductCheckout(id, username, successUrl, cancelUrl, language).mapError {
                        Error.UnavailableProductCheckout(it)
                    }.getOrAbort()
                    success(productCheckout)
                }
            }
        }
}

internal class GetProductDashboard(
    private val returnUrl: String?
): OutcomeUseCase<ProductModel.Model.ProductsDashboard, GetProductDashboard.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.PROFILE, UseCaseId.GET_PRODUCT_DASHBOARD_URL, errorId, networkError) {
        data class UnavailableProductDashboard(val error: NetworkError) : Error(
            asErrorId<UnavailableProductDashboard>(1),
            error
        )
        object NoUserSession : Error(
            asErrorId<NoUserSession>(2)
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val productService: ProductService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configService: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<ProductModel.Model.ProductsDashboard, Error>
        get() = {
            val username = authService.getStoredAuth()?.username
            if (username == null) {
                failure(Error.NoUserSession)
            } else {
                val user = userService.getUser(username).getOrNull()
                val language = user?.preferredLanguage ?: configService.getCoreConfig().signup.defaultLanguage
                val userPortalSession = productService.getProductsDashboard(username, returnUrl, language)
                    .mapError {
                        Error.UnavailableProductDashboard(it)
                    }.getOrAbort()
                success(userPortalSession)
            }
        }
}


internal expect class Logout() : UseCase<Unit>