package es.cinfo.tiivii.user.profile.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.features.ar.view.ArView.Event.GetPayload
import es.cinfo.tiivii.core.interest.model.ViewModel.Interest
import es.cinfo.tiivii.core.modules.avatar.model.AvatarModel.ViewModel.Avatar
import es.cinfo.tiivii.core.modules.game.model.GameModel.ViewModel.Achievement
import es.cinfo.tiivii.core.modules.game.model.GameModel.ViewModel.Reward
import es.cinfo.tiivii.core.modules.product.model.ProductModel.ViewModel.Product
import es.cinfo.tiivii.core.modules.product.model.ProductModel.ViewModel.ProductCheckout
import es.cinfo.tiivii.core.modules.product.model.ProductModel.ViewModel.ProductsDashboard
import es.cinfo.tiivii.core.userstats.UserStatsModel.ViewModel.Ranking
import es.cinfo.tiivii.core.util.LoadingModel.ViewModel.LoadState
import es.cinfo.tiivii.user.profile.view.UserView.Event
import es.cinfo.tiivii.user.profile.view.UserView.Model
import es.cinfo.tiivii.user.role.RoleViewModel

/**
 * Interface representing the exposed data and functionalities for the user related logic
 */
interface UserView : MviView<Model, Event> {

    /**
     * Operations available to be requested
     * @see ReloadUserData
     * @see ReloadUserStats
     * @see Logout
     * @see GetAvailableInterests
     * @see GetAvailableAvatars
     * @see UpdateInterest
     * @see UpdateLanguage
     * @see UpdateAvatar
     * @see UpdateCredentials
     * @see UpdateFirstName
     * @see UpdateLastName
     * @see PublishContent
     * @see DeleteAccount
     * @see LogUserProfileView
     * @see GetProductCheckout
     * @see ReloadProducts
     * @see LoadMoreUserProducts
     * @see CheckCredentialsSecurity
     * @see GetProductsDashboard
     */
    sealed class Event {

        /**
         * Requests the user data to be updated
         */
        object ReloadUserData : Event()

        /**
         * Requests the user stats (ranking, achievements, etc.) to be reloaded
         */
        object ReloadUserStats: Event()

        /**
         * Requests the logout of the current user clearing auth and related login data
         */
        object Logout : Event()

        /**
         * Requests the available interests the user can select from
         */
        object GetAvailableInterests : Event()

        /**
         * Requests the available avatars the user can select from
         */
        object GetAvailableAvatars : Event()

        /**
         * Requests the update of the current user interests with the given ones
         * @param interests new list of user interests to save
         */
        data class UpdateInterest(val interests: List<Interest>) : Event()

        /**
         * Requests the update of the user preferred language
         * @param language new user preferred language
         */
        data class UpdateLanguage(val language: String) : Event()

        /**
         * Requests the update of the user avatar
         * @param avatar new user preferred avatar
         */
        data class UpdateAvatar(val avatar: Avatar) : Event()

        /**
         * Requests the update of the user credentials
         * @param oldCredentials to verify user identity
         * @param newCredentials to be stored for next authentications
         */
        data class UpdateCredentials(val oldCredentials: String, val newCredentials: String) : Event()

        /**
         * Requests the update of the user first name
         * @param firstName new user's first name
         */
        data class UpdateFirstName(val firstName: String) : Event()

        /**
         * Requests the update of the user last name
         * @param lastName new user's last name
         */
        data class UpdateLastName(val lastName: String) : Event()

        /**
         * Requests a confirmation/denial of a content publication by a tutor depending on the payload. This
         * will result in an output of type [Output.ContentPublication] notifying if the publication was granted or
         * denied
         * @param contentId id of the content to be published/rejected
         * @param payload to authorize publish/reject the content publication
         */
        data class PublishContent(val contentId: Int, val payload: String) : Event()

        /**
         * Requests to delete the current user account, clearing the all related information
         */
        object DeleteAccount : Event()

        /**
         * Requests a user profile screen view to be made. It should be sent
         * whenever the user profile screen is viewed. This event is exposed for more grained control
         * for the UI
         */
        object LogUserProfileView : Event()

        /**
         * Requests that a [Product] checkout is wanted to make a [Product] purchase. This will load a
         * [ProductCheckout] in [Model.latestProductCheckout] that can be used to redirect the user to the related
         * purchase
         * @param id of the [Product] to be purchased
         * @param successUrl redirect url after payment checkout
         * @param cancelUrl redirect url if the payment is cancelled
         */
        data class GetProductCheckout(val id: Int, val successUrl: String? = null, val cancelUrl: String? = null) : Event()

        /**
         * Request a re-load of all available products
         */
        object ReloadProducts: Event()

        /**
         * Requests a load of more user purchased products
         */
        object LoadMoreUserProducts: Event()

        /**
         * Triggers a security check over the given [credentials]. This may trigger an [Output.InsecureCredentials] or
         * [Output.SecureCredentials]
         * @param credentials to be checked
         */
        data class CheckCredentialsSecurity(val credentials: String): Event()

        /**
         * Indicates the core that the user [Product]'s dashboard needs to be loaded. This will load a
         * [ProductsDashboard] in [Model.latestProductsDashboard] that can be used to redirect the users products dashboard
         * @param returnUrl redirect url when coming back from the users products dashboard
         */
        data class GetProductsDashboard(val returnUrl: String?) : Event()
    }

    /**
     * The data model exposed to the UI
     * @param loadingUser indicates if the status of the user data load
     * @param availableRoles indicates if the list of available [RoleViewModel]'s of the application
     * @param username indicates the username of the loaded user
     * @param email indicates the email of the loaded user
     * @param birthday indicates the birthday of the loaded user in the format of 'YYYY-MM-DD'
     * @param availableLanguages indicates the list of available languages of the application
     * @param language indicates the preferred language of the loaded user
     * @param availableAvatars indicates the list of available [Avatar]'s of the application
     * @param interests indicates the list of interests of the loaded user
     * @param credentials indicates the credentials of the loaded user
     * (only filled when the [Event.UpdateCredentials] has been previously sent)
     * @param firstName indicates the first name of the loaded user
     * @param lastName indicates the last name of the loaded user
     * @param loadingUserStats indicates the loading state of the user related stats ([ranking], [achievements], [rewards])
     * @param ranking indicates the [Ranking] of the loaded user
     * @param achievements indicates the [Achievement]'s of the loaded user
     * @param rewards indicates the [Reward]'s of the loaded user
     * @param loadingProducts indicates the loading state of the [Product]'s the user has purchased
     * @param products indicates the list of available [Product]'s to purchase on the application
     * @param userProducts indicates the list of user purchased [Product]'s
     * @param loadingProductCheckout indicates the loading state of the latest [ProductCheckout] requested through [Event.GetProductCheckout]
     * @param latestProductCheckout indicates the latest [ProductCheckout] loaded
     * @param acceptedLegalConditions indicates the text of the latest legal conditions accepted
     * @param loadingProductsDashboard indicates the loading stat of the latest [ProductsDashboard] requested through [Event.GetProductsDashboard]
     * @param latestProductsDashboard indicates the latest [ProductsDashboard] loaded
     */
    data class Model(
        val loadingUser: LoadState,
        val availableRoles: Set<RoleViewModel>,
        val username: String?,
        val email: String?,
        val birthday: String?,
        val availableLanguages: Set<String>,
        val language: String?,
        val availableAvatars: Set<Avatar>,
        val avatar: Avatar?,
        val availableInterests: Set<Interest>,
        val interests: Set<Interest>,
        val credentials: String?,
        val firstName: String?,
        val lastName: String?,
        val loadingUserStats: LoadState,
        val ranking: Ranking?,
        val achievements: List<Achievement>,
        val rewards: List<Reward>,
        val loadingProducts: LoadState,
        val products: List<Product>,
        val userProducts: List<Product>,
        val loadingProductCheckout: LoadState,
        val latestProductCheckout: ProductCheckout?,
        val acceptedLegalConditions: String?,
        val loadingProductsDashboard: LoadState,
        val latestProductsDashboard: ProductsDashboard?,
    )

    sealed class Output {
        /**
         * Unexpected error happened
         * @param code string representation of an internal error code to be shown with the generic error to the user
         */
        data class UnexpectedError(val code: String) : Output()

        /**
         * User session has expired. Re-log is necessary
         */
        object UserSessionExpired : Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : Output()

        /**
         * User log out confirmation
         */
        object LoggedOut : Output()

        /**
         * Account deleted confirmation
         */
        object AccountDeleted : Output()

        /**
         * No user has logged in
         */
        object NoUserLoggedIn : Output()

        /**
         * Unexpected user data load failure
         */
        object UserDataLoadFailed : Output()

        /**
         * Unexpected avatars retrieval failure
         */
        object AvatarsRetrievalFailed : Output()

        /**
         * Unexpected interests retrieval failed
         */
        object InterestsRetrievalFailed : Output()

        /**
         * Unexpected first name update failure
         */
        object FirstNameUpdateFailed : Output()

        /**
         * Unexpected last name update failure
         */
        object LastNameUpdateFailed : Output()

        /**
         * Unexpected avatar update failure
         */
        object AvatarUpdateFailed : Output()

        /**
         * Unexpected interests update failure
         */
        object InterestsUpdateFailed : Output()

        /**
         * Unexpected language update failure
         */
        object LanguageUpdateFailed : Output()

        /**
         * Inputted credentials do not match on server
         */
        object InvalidCredentials : Output()

        /**
         * Notifies the user that the inputted credentials do not meet security requirements
         */
        object InsecureCredentials : Output()

        /**
         * Notifies the user that the inputted credentials through [Event.CheckCredentialsSecurity] are secure
         */
        object SecureCredentials : Output()

        /**
         * Unexpected credentials update failure
         */
        object CredentialsUpdateFailed : Output()

        /**
         * Unexpected delete account failure
         */
        object DeleteAccountFailed : Output()

        /**
         * Notifies if a content publication through [Event.PublishContent] has been confirmed or denied
         * @param granted indicates if the publication has been confirmed or declined
         */
        data class ContentPublication(val granted: Boolean) : Output()

        /**
         * An operation has been request through an [Event] but the operation cannot be done due to some requirements
         * not met. This may happen if an [Event] is requested when is not due. This usually means that the usage of the
         * internal of the component is wrong and should be checked
         * @param reason explains why the operation cannot be executed
         */
        data class IllegalOperationError(val reason: String) : Output()
    }

}
