package es.cinfo.tiivii.core.modules.product.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetProductCheckoutApiRequest(
    @SerialName(SUCCESS_URL_PARAM)
    val successUrl: String?,
    @SerialName(CANCEL_URL_PARAM)
    val cancelUrl: String?
) {
    companion object {
        const val SUCCESS_URL_PARAM = "successUrl"
        const val CANCEL_URL_PARAM = "cancelUrl"
    }
}

@Serializable
data class GetUserPortalSessionApiRequest(
    @SerialName(RETURN_URL_PARAM)
    val returnUrl: String?,
) {
    companion object {
        const val RETURN_URL_PARAM = "returnUrl"
    }
}